<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row class="m-1">
            <b-col cols="12">
                <b-form-group label="Tipo Documento" label-for="document_type" label-class="font-weight-bold">
                    <v-select 
                        v-model="type_document"
                        id="document_type"
                        :options="[
                            { value: 'R', label: 'Recepción'},
                            { value: 'C', label: 'Compras'}
                        ]"
                        :reduce="option => option.value"
                        name="label"
                        :clearable="false"
                        :searchable="false"
                        class="select-size-sm"
                        @input="getLastPurcases">
                    </v-select>
                </b-form-group>
            </b-col>
        </b-row>

        <div class="position-relative b-table-responsive mb-0_ small my-small text-small b-table-sticky-header table-responsive">            
            <table role="table" aria-busy="false" aria-colcount="6" class="table b-table table-sm b-table-no-border-collapse" id="__BVID__405">
                <thead role="rowgroup" class="">
                    <tr role="row" class="">
                        <th role="columnheader" scope="col" aria-colindex="1" class="table-b-table-default" style="font-size: 8px !important;">
                            <div>Proveedor</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex="2" class="table-b-table-default" style="font-size: 8px !important;">
                            <div>Folio</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex="3" class="table-b-table-default" style="font-size: 8px !important;">
                            <div>Fecha</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex="4" class="table-b-table-default" style="font-size: 8px !important;">
                            <div>Costo</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex="5" class="table-b-table-default" style="font-size: 8px !important;">
                            <div>Costo Real</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex="6" class="table-b-table-default" style="font-size: 8px !important;">
                            <div>Unidades</div>
                        </th>
                    </tr>
                </thead>
                <tbody role="rowgroup">
                    <tr v-for="(item, index) in lastPurchases" :key="index" role="row" class="">
                        <td style="font-size: 9px!important;" :aria-colindex="index + 1" role="cell" class="text-nowrap text-small">
                            <span class="text-truncate">{{ item.proveedor }}</span>
                        </td>
                        <td style="font-size: 9px!important;" :aria-colindex="index + 2" role="cell" class="text-nowrap text-small small">{{ item.recepcion }}</td>
                        <td style="font-size: 9px!important;" :aria-colindex="index + 3" role="cell" class="text-nowrap text-small small"><span class="text-capitalize">{{ formatDate(item.fecha) }}</span></td>
                        <td style="font-size: 9px!important;" :aria-colindex="index + 4" role="cell" class="text-nowrap text-small small">{{ formatCurrency(item.costo) }}</td>
                        <td style="font-size: 9px!important;" :aria-colindex="index + 5" role="cell" class="text-nowrap text-small small">{{ formatCurrency(item.costoReal) }}</td>
                        <td style="font-size: 9px!important;" :aria-colindex="index + 6" role="cell" class="text-nowrap text-small small">{{ item.unidades }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
                    

        <!-- <b-table :items="lastPurchases" responsive :fields="columnLastPurchases" 
            class="position-relative b-table-responsive mb-0_ small my-small text-small"
            :sticky-header="true" :no-border-collapse="true" small 
            show-empty 
            empty-text="No se encontraron registros"
            :busy.sync="loading"
            style="max-height: 300px!important;"
            >

            <template slot="empty">
                <h6 class="text-center mt-1">
                    No se encontraron registros
                </h6>
            </template>

            <template #cell(proveedor)="data">
                <span class="text-truncate">{{ data.item.proveedor }}</span>
            </template>

            <template #cell(costo)="data">
                {{ formatCurrency(data.item.costo) }}
            </template>

            <template #cell(costoReal)="data">
                {{ formatCurrency(data.item.costoReal) }}
            </template>

            <template #cell(fecha)="data">
                <span class="text-capitalize">{{ formatDate(data.item.fecha) }}</span>
            </template>
        </b-table> -->
    </b-overlay>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BInputGroup, BFormGroup, BFormCheckbox, BFormFile, BInputGroupPrepend, BFormInvalidFeedback,
    BCardText, BCard, BCardHeader, BCol, BRow, BImg, BMedia, BAvatar, BOverlay, BSpinner, BFormRadioGroup, BFormDatepicker, BTable, BTableLite, BTooltip,
    VBTooltip, VBToggle, BBreadcrumb, BPagination, BCollapse, BLink, BTabs,  BTab, BNavItem, BAlert, BIcon,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, computed, onMounted, watch, reactive, defineEmits } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers'

import PricesArticulosProvider from "@/providers/PricesArticles";
const PricesArticulosResource = new PricesArticulosProvider();

import PurchasesProvider from "@/providers/Purchases"
const PurchasesResource = new PurchasesProvider()

export default {
    name: 'LastPurchases',
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BFormCheckbox,
        BFormFile,
        BInputGroupPrepend,
        BFormInvalidFeedback,
        BCardText,
        BCard,
        BCardHeader,
        BCol,
        BRow,
        BImg,
        BMedia,
        BAvatar,
        BOverlay,
        BSpinner,
        BFormRadioGroup,
        BFormDatepicker,
        BTable,
        BTableLite,
        BTooltip,
        VBTooltip,
        BBreadcrumb,
        BPagination,
        BCollapse,
        BLink,
        BNavItem,
        BTabs, 
        BTab,
        BAlert,
        BIcon,

        //
        vSelect,
       
    },
    directives: {
        "b-modal": VBModal,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        active: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        article: {
            type: Object,
            required: true,
            default: () => {}
        },
        connection: {
            type: Number,
            required: true,
            default: () => 0,
        },
        branch: {
            type: Number,
            required: false,
            default: () => 0,
        },
        warehouse: {
            type: Number,
            required: true,
            default: () => 0,
        },
    },
    setup(props, { emit }) {
        
        const loading = ref(false)
        const type_document = ref('R')
        const lastPurchases = ref([])
        
        //Table last purchases
        const columnLastPurchases = [
            {
                key: 'proveedor',
                label: 'Proveedor',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important;',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'recepcion',
                label: 'Recepción',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'fecha',
                label: 'Fecha',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'costo',
                label: 'Costo',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'costoReal',
                label: 'Costo Real',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'unidades',
                label: 'Unidades',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },

        ]


        const getLastPurcases = async() => {
            if (props.article && props.article.articulo_Id && props.connection) {
                try {
                    loading.value = true
                    const { data } = await PurchasesResource.ObtenerUltimaCompraPorConexion(props.connection, props.article.articulo_Id, type_document.value)
                    loading.value = false

                    if (data.isSuccesful) {
                        lastPurchases.value = data.data                    
                    } else {
                        helper.danger(data.message)
                    }
                }catch(e) {
                    loading.value = false
                    helper.handleResponseErrors(e)
                }
            }
        }

        getLastPurcases()

        watch([type_document], () => {
            // getLastPurcases()
        })

        return {
            loading,
            type_document,
            columnLastPurchases,
            lastPurchases,

            //
            getLastPurcases,
        }

    },

}
</script>

<style>
.text-truncate {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 150px;
}
</style>